<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Demo Page</div>
        <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
        </div>
    </div>
    <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
        <div class="company-box-outer">
            copy Example First
            <div>
                <input v-on:focus="$event.target.select()" ref="myinput" readonly :value="text" />
                <button type="button" title="Click To Copy" @click="copyText()" class="btn custom-view-detail-btn">
                    Copy
                </button>
            </div>
            copy Example Second
            <div class="mt-3">
                <input v-on:focus="$event.target.select()" ref="myinputtxt" readonly title="Click To Copy"
                    @click="copyTextbtn()" :value="textsec" />
            </div>
            <div class="mb-3 mt-3">
                976XXXX226
            </div>
            <div class="mb-3">
                {{ maskedEmail(emailvalue) }}
            </div>
            <div class="mb-3">
                {{ maskedNumber(originalNumber) }}
            </div>
            <div class="mb-3">
                {{ maskedVoterIdNumber(voterNumber) ? maskedVoterIdNumber(voterNumber) : "N/A" }}
            </div>
            <div class="custom-form-group">
                <label for="formLastNameInput" class="form-label">Middle Name</label>
                <input type="text" v-model="middlename" class="form-control text-capitalize" id="formLastNameInput"
                    placeholder="Enter Middle Name" autocomplete="off" maxlength="50"
                    @keypress="isLetterWithOnlyTwoSpace($event)" />
            </div>
            <button type="button" @click="confirmModalOpen()" class="btn custom-view-detail-btn" :style="{ width: '70px' }">
                <span v-if="!showupdateloader">Update</span>
                <div class="spinner-border custom-spinner-icon-loader-btn" role="status" v-if="showupdateloader">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <Calendar v-model="birthdate" :minDate="minDateBirth" :maxDate="maxDateBirth" :showIcon="true"
                        class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                        :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                        :yearNavigator="true">
                    </Calendar>
                </div>
                maxDateBirth: moment().subtract(1, "years")._d,
                minDateBirth: moment().subtract(120, "years")._d,
            </div>
            <div>Data Time Stamp Convert</div>
            <div>{{ format_timestamp(this.datavalue) }}</div>
            <div>{{ firstCapitalize(this.textconvert) }}</div>
            <div class="card flex flex-column align-items-cente">
                <div class="flex flex-wrap gap-2 mb-4">
                    <Button type="button" icon="pi pi-plus" label="Expand All" @click="expandAll" />
                    <Button type="button" icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                </div>
                <Tree v-model:expandedKeys="expandedKeys" :value="nodes" class="w-full md:w-30rem"></Tree>
            </div>
        </div>
    </div>

    <!-- confirmation modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="confirmModalProcessBtn()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation modal end here -->
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            mobileno: "9762468226",
            emailvalue: "tushar.sk55@gmail.com",
            originalNumber: 'MH1220100050175',
            showupdateloader: false,
            confirm_popup_status: false,
            showconfirmloaderbtn: false,
            birthdate: null,
            maxDateBirth: moment().subtract(1, "years")._d,
            minDateBirth: moment().subtract(120, "years")._d,
            datavalue: '',
            middlename: '',
            text: 'Tushar Salunkhe',
            textsec: "tushar rock",
            voterNumber: 'ZLO1535483',
            panNumber: 'MH1220100050175',
            nodes: [],
            expandedKeys: {},
            textconvert : "tushar Salunkhe",
        };
    },
    computed: {
    },
    watch: {
    },
    methods: {
        firstCapitalize(e) {
            const str = e;
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            const str2 = arr.join(" ");
            return str2;
        },
        copyText() {
            this.$refs.myinput.focus();
            document.execCommand('copy');
        },
        copyTextbtn() {
            this.$refs.myinputtxt.focus();
            document.execCommand('copy');
            var copyMsg = "Text Copied!";
            this.$toast.open({
                message: copyMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
            });
        },
        maskedNumber(getvalue) {
            const maskedDigits = 'X'.repeat(getvalue.length - 4);
            const FirstThreeDigits = getvalue.slice(0, 2);
            const lastThreeDigits = getvalue.slice(-2);
            return FirstThreeDigits + maskedDigits + lastThreeDigits;
        },
        maskedVoterIdNumber(getvalue) {
            const maskedDigits = 'X'.repeat(getvalue.length - 4);
            const FirstThreeDigits = getvalue.slice(0, 2);
            const lastThreeDigits = getvalue.slice(-2);
            return FirstThreeDigits + maskedDigits + lastThreeDigits;
        },
        maskedEmail(getvalue) {
            const [name, domain] = getvalue.split('@');
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        },
        maskedEmailMask(getvalue) {
            const [name, domain] = getvalue.split('@');
            const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
            if(name.length == 1 && !lastdomain) {
                return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
            } else if(name.length == 1 && lastdomain) {
                return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
            } else {
                return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
            }
            },
        confirmModalOpen() {
            this.confirm_popup_status = true;
        },

        confirmModalClose() {
            this.confirm_popup_status = false;
        },
        confirmModalProcessBtn() {
            this.showconfirmloaderbtn = true;
            this.confirm_popup_status = false;
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },

        isLetterWithOnlyTwoSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s][A-Za-z\s]$/.test(charspace)) return true;
            else e.preventDefault();
        },
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ' - ' + pair[1]);
        // }
        expandAll() {
            for (let node in this.nodes) {
                this.expandNode(this.nodes[node]);
            }
            this.expandedKeys = { ...this.expandedKeys };
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        }
    },
};
</script>
<style scoped>
.company-detail-box-outer {
    position: relative;
    height: 525px;
    overflow: auto;
}

.company-box-outer {
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    margin-top: 20px;
    height: 400px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
</style>